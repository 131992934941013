<!--Navigation.vue-->
<template>
    <at-menu class="navbar container-fluid" router mode="horizontal">
        <div class="container-menu-mobile">
            <button class="menu-mobile" @click="toggleMobileMenu">
                <img src="../icon/menu_open.svg" alt="menu" />
            </button>
        </div>
        <navigation-list v-if="windowWidth > 992" class="navigation-list-mobile" :modules="modules"></navigation-list>
        <current-company-dropdown :user="user"></current-company-dropdown>
        <theme-switcher-dropdown></theme-switcher-dropdown>
        <div class="nav-settings">
            <at-dropdown v-if="loggedIn" placement="bottom-right" @on-dropdown-command="userDropdownHandle">
                <user-avatar :border-radius="10" :user="user"></user-avatar>
                <at-dropdown-menu slot="menu">
                    <template v-for="(item, key) of userDropdownItems">
                        <at-dropdown-item :key="key" :name="item.to.name">
                            <span><i class="icon" :class="[item.icon]"></i>{{ item.title }}</span>
                        </at-dropdown-item>
                    </template>
                    <li class="at-dropdown-menu__item" @click="logout()">
                        <i class="icon icon-log-out"></i> {{ $t('navigation.logout') }}
                    </li>
                </at-dropdown-menu>
            </at-dropdown>
        </div>
    </at-menu>
</template>

<script>
    import { mapGetters } from 'vuex';
    import UserAvatar from '@/components/UserAvatar';
    import CurrentCompanyDropdown from '@/components/CurrentCompanyDropdown';
    import ThemeSwitcherDropdown from '@/components/ThemeSwitcherDropdown.vue';
    import NavigationList from '@/components/NavigationList.vue';

    export default {
        components: {
            NavigationList,
            ThemeSwitcherDropdown,
            UserAvatar,
            CurrentCompanyDropdown,
        },
        props: {
            isMobileMenuOpen: {
                type: Boolean,
                default: false,
            },
            modules: {
                type: Array,
                required: true,
            },
        },
        methods: {
            toggleMobileMenu() {
                this.isMobileMenuOpenLocal = !this.isMobileMenuOpenLocal;
                this.$emit('update:is-mobile-menu-open', this.isMobileMenuOpenLocal);
            },
            userDropdownHandle(route) {
                this.$router.push({ name: route });
            },
            async logout() {
                await this.$store.dispatch('user/forceUserExit', {
                    router: this.$router,
                });
            },
        },
        computed: {
            windowWidth() {
                return window.innerWidth;
            },
            ...mapGetters('user', ['user']),
            userDropdownItems() {
                const items = [
                    // {
                    //     name: 'about',
                    //     to: {
                    //         name: 'about',
                    //     },
                    //     title: this.$t('navigation.about'),
                    //     icon: 'icon-info',
                    // },
                    // {
                    //     name: 'desktop-login',
                    //     to: {
                    //         name: 'desktop-login',
                    //     },
                    //     title: this.$t('navigation.client-login'),
                    //     icon: 'icon-log-in',
                    // },
                ];
                this.modules.forEach(m => {
                    const entriesDropdown = m.getNavbarMenuEntriesDropDown();
                    Object.keys(entriesDropdown).forEach(el => {
                        const { displayCondition, label, to, click, icon } = entriesDropdown[el];
                        if (displayCondition(this.$store)) {
                            items.push({
                                to,
                                icon,
                                click,
                                title: this.$t(label),
                            });
                        }
                    });
                });

                return items;
            },
            rules() {
                return this.$store.getters['user/allowedRules'];
            },
            loggedIn() {
                return this.$store.getters['user/loggedIn'];
            },
        },
        watch: {
            isMobileMenuOpen(newVal) {
                this.isMobileMenuOpenLocal = newVal;
                this.$emit('update:is-mobile-menu-open', newVal);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .theme-dark .navbar {
        &::v-deep {
            .at-dropdown {
                .at-dropdown-menu__item:hover {
                    background-color: transparent;
                }
            }
        }
        .container-menu-mobile {
            button {
                display: inline-block;
                background-color: transparent;
            }
        }
    }
    .navbar {
        border: 1px #f1f1f4 solid;
        box-shadow: 10px 0px 28px 0 rgba(82, 63, 105, 0.2);
        display: flex;
        height: auto;
        justify-content: flex-end;
        padding: 0.75em 24px;
        .container-menu-mobile {
            display: none;
            flex-grow: 1;
            button {
                display: inline-block;
                border: none;
                background: #fff;
                cursor: pointer;
                vertical-align: -6px;
            }
        }

        .nav-items {
            display: flex;
            flex-grow: 1;
        }

        &::v-deep {
            .at-menu {
                &__item-link {
                    &::after {
                        bottom: -0.75em;
                        height: 3px;
                    }
                }
            }

            .at-menu__submenu-title {
                padding-right: 0;
                display: inline-block;
            }

            .at-dropdown {
                align-items: center;
                display: flex;

                &-menu {
                    overflow: hidden;

                    &__item {
                        color: #4b5675;
                        font-weight: 400;

                        &:hover {
                            background-color: #fff;
                        }
                    }
                }

                &__trigger {
                    align-items: center;
                    cursor: pointer;
                    display: flex;

                    .icon {
                        margin-right: 8px;
                    }
                }

                &__popover {
                    width: fit-content;
                }

                .at-dropdown-menu__item .icon {
                    margin-right: 6px;
                }

                .at-dropdown-menu__item:hover {
                    color: $color-orange;
                    background-color: #f9f9f9;
                }

                .at-dropdown-menu__item:hover .icon {
                    color: $color-orange;
                }
            }
        }
    }
    @media (max-width: 1126px) {
        .navbar {
            .navigation-list-mobile {
                display: none;
            }
            .at-dropdown {
                margin: 4px;
            }
            .container-menu-mobile {
                display: block;
            }
        }
    }
</style>
